import tippy from 'tippy.js';

"use strict";

// Menu toggle
const menuTrigger = $('.menu-trigger > .button');
const menubar = $('.menu-area .navbar');
menuTrigger.on('click', () => {
    menubar.slideToggle(400);
});

// Check if the HTML language is Greek
document.addEventListener('DOMContentLoaded', function() {
    if (document.documentElement.lang === 'el') {
        // Change the text of elements with the class .pdfprnt-button-title to Εκτύπωση
        document.querySelectorAll('.pdfprnt-button-title').forEach(function(element) {
            element.textContent = 'Εκτύπωση';
        });
    }
});

// Dropdown button
$('.dropdown-button-area').each((index, element) => {
    const dropdownButton = $(element).find('.dropdown-button');
    const dropdownMenu = $(element).find('.dropdown-menu');

    dropdownButton.on('click', () => {
        dropdownMenu.slideToggle(400);
    });

    // Close submenu when clicking outside the menu
    $(document).on('click', function (e) {
        if (!$(e.target).closest('.dropdown-button-area').length) {
            dropdownMenu.slideUp(400)
        }
    });
});

// Accordion
$('.accordion-area').each((index, element) => {
    const accordion = $(element).find('.accordion');
    accordion.each((index, element) => {
        const accordionItem = $(element);
        const accordionHeadline = accordionItem.find('.accordion-headline');
        const accordionContent = accordionItem.find('.accordion-content');

        accordionHeadline.on('click', () => {
            accordionContent.slideToggle(400);
        });
    });
});

// Initialize Tippy.js for Tootltip
document.addEventListener('DOMContentLoaded', function() {
    tippy('.tooltip', {
        placement: 'top',
        animation: 'fade',
        arrow: true,
        content(reference) {
            return reference.getAttribute('title');
        },
    });
});

// Go to top
$('.go-to-top').on('click', function(e){
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, 400);
});